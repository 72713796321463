import React from "react";
import { Typography } from "@mui/material";
import { VendorLogic } from "@/src/model";
import { VendorType } from "@/src/type";
import { DetailSectionInfo } from "@src/component/partial";
import styles from "./VenueVendor.module.scss";
import VenueVendorItem from "./VenueVendorItem";

interface VenueVendorProps {
  vendors?: Array<VendorType.Vendor>;
  isExclusive?: boolean;
}

function VenueVendor({ vendors, isExclusive }: VenueVendorProps) {
  if ((vendors ?? []).length <= 0) {
    return <></>;
  }

  const exclusive = VendorLogic.parseVendorsByPivotType("standard", vendors);
  const preferred = VendorLogic.parseVendorsByPivotType("preferred", vendors);

  const exclusiveTextForExclusiveVendors = `
    At BLACE, our goal is the success of your event. To guarantee you the best
    event experience possible and maintain our high standards, we require our
    exclusive vendors to be involved in your event at this venue. If you have
    other vendor preferences, our team is happy to discuss your options with you.
  `;

  const nonExclusiveTextForExclusiveVendors = `
    Exclusive vendors have an intimate knowledge of the space and are usually
    required to be involved with events.
  `;

  const exclusiveTextForPreferredVendors = `
    At BLACE, we have hand-picked these vendors for their exceptional service
    and extensive experience with this venue. We strongly recommend using our
    preferred vendors to ensure a seamless event production.
  `;

  const nonExclusiveTextForPreferredVendors = `
    Preferred vendors have been hand-picked to deliver exceptional service in
    the space. It is strongly recommended these vendors are used to ensure a
    seamless event production.
  `;

  return (
    <>
      {exclusive?.length > 0 && (
        <DetailSectionInfo
          title={"Exclusive Vendors"}
          Component={
            <>
              <div className={styles.vendorTypeDescription}>
                <Typography variant="h6" color="grey.700">
                  {isExclusive ? exclusiveTextForExclusiveVendors : nonExclusiveTextForExclusiveVendors}
                </Typography>
              </div>
              <div className={styles.venueVendorList}>
                {exclusive.map((vendor, key) => (
                  <VenueVendorItem key={key} vendor={vendor} />
                ))}
              </div>
            </>
          }
        />
      )}
      {preferred?.length > 0 && (
        <DetailSectionInfo
          title={"Preferred Vendors"}
          Component={
            <>
              <div className={styles.vendorTypeDescription}>
                <Typography variant="h6" color="grey.700">
                  {isExclusive ? exclusiveTextForPreferredVendors : nonExclusiveTextForPreferredVendors}
                </Typography>
              </div>
              <div className={styles.venueVendorList}>
                {preferred.map((vendor, key) => (
                  <VenueVendorItem key={key} vendor={vendor} />
                ))}
              </div>
            </>
          }
        />
      )}
    </>
  );
}

export default React.memo(VenueVendor);
